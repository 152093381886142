<style scoped>
</style>
<template>
  <div class="containers" style="background: #aaa">
    <live-hw-rtc-cmp></live-hw-rtc-cmp>
  </div>
</template>


<script>
/* eslint-disable */

import liveHwRtcCmp from "@/widgets/liveHwRtcCmp";

export default {
  name: "Test",
  components: {
    liveHwRtcCmp,
  },
  data() {
    return {};
  },
  methods: {},
  created() {},
  async destroyed() {},
};
</script>
